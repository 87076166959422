import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  ticketCommentConverter,
  ticketConverter,
} from 'data/converters/ticket.converters';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import {
  TCreateTicket,
  TCreateTicketUi,
  TTicket,
  TTicketComment,
  TTicketCommentUi,
  TTicketStatus,
  TTicketUi,
} from 'data/types/ticket.types';

import { authSplitApi } from 'redux/helpers/slice.helpers';
import { TUpdateAttachmentUi } from 'data/types/generalDataTypes';
import { attachmentsUpdateConverter } from 'data/converters/general.converters';

const TICKETS_BASE_URL = 'api/cp/tickets';
const GET_ALL_TICKETS = `${TICKETS_BASE_URL}/filter`;

const domain = window.location.hostname;
export const ticketsApi = authSplitApi('tickets', [
  'Tickets',
  'Ticket',
  'TicketComments',
  'TicketMaterialForQuote',
  'TicketMaterialForInvoice',
]).injectEndpoints({
  endpoints: build => ({
    createTicket: build.mutation<TCreateTicketUi, TCreateTicket>({
      query(body) {
        return {
          url: TICKETS_BASE_URL,
          method: 'POST',
          body: ticketConverter.toDb(body),
        };
      },
      invalidatesTags: [{ type: 'Tickets' }],
    }),
    getAllTickets: build.query<
      TPageableDataWithContentUi<TTicketUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string[];
        filters?: any;
        search?: string;
        status: TTicketStatus;
      }
    >({
      query({ page, pageSize, sort, filters, search, status }) {
        const sortParam = sort && sort.join("&sort=");
        const urlWithParams = GET_ALL_TICKETS.concat(
          `?page=${page}&size=${pageSize}&sort=${sortParam}`,
        );

        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(filters && { ...filters }),
            ...(search && { search }),
            cpDomain: domain,
            statusToSearchFor: status
          },
        };
      },
      providesTags: [{ type: 'Tickets' }],
      transformResponse: (data: TPageableDataWithContent<TTicket[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(ticket => ticketConverter.fromDb(ticket))
            : [],
        };
      },
    }),
    getTicket: build.query<TTicketUi, string>({
      query(resourceId) {
        return {
          url: `${TICKETS_BASE_URL}/${resourceId}`,
          method: 'GET',
          params: {
            cpDomain: domain,
          },
        };
      },
      transformResponse: (data: { ticket: TTicket }) =>
        ticketConverter.fromDb(data.ticket),
      providesTags: ['Ticket'],
    }),
    getTicketComments: build.query<
      TTicketCommentUi[],
      {
        ticketId: string;
      }
    >({
      query({ ticketId }) {
        const urlWithParams = TICKETS_BASE_URL.concat(`/${ticketId}/comments`);
        return {
          url: urlWithParams,
          method: 'GET',
        };
      },
      providesTags: ['TicketComments'],
      transformResponse: (data: TTicketComment[]) => {
        return data.map(comment => ticketCommentConverter.fromDb(comment));
      },
      keepUnusedDataFor: 0,
    }),
    saveTicketComment: build.mutation<
      void,
      {
        ticketId: string;
        text: string;
        replyToCustomer: boolean;
      }
    >({
      query({ ticketId, text, replyToCustomer }) {
        return {
          url: TICKETS_BASE_URL.concat(`/${ticketId}/comment`),
          method: 'PATCH',
          body: {
            text,
            replyToCustomer,
          },
        };
      },
      invalidatesTags: ['TicketComments'],
    }),
    editTicketComment: build.mutation<
      void,
      {
        commentId: string;
        value: string;
      }
    >({
      query({ commentId, value }) {
        return {
          url: TICKETS_BASE_URL.concat(`/comments/${commentId}/text`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['TicketComments'],
    }),
    deleteTicketCommentById: build.mutation<
      void,
      {
        commentId: string;
      }
    >({
      query({ commentId }) {
        return {
          url: TICKETS_BASE_URL.concat(`/comments/${commentId}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['TicketComments'],
    }),
    markUsReadTicketComment: build.mutation<
      void,
      {
        commentId: string;
      }
    >({
      query({ commentId }) {
        return {
          url: TICKETS_BASE_URL.concat(`/comments/${commentId}/mark-as-read`),
          method: 'PATCH',
        };
      },
      invalidatesTags: ['TicketComments'],
    }),
    updateTicketAttachments: build.mutation<
      void,
      {
        ticketId: string;
        attachments: TUpdateAttachmentUi[];
      }
    >({
      query({ ticketId, attachments }) {
        return {
          url: `${TICKETS_BASE_URL}/${ticketId}/attachments`,
          method: 'PATCH',
          body: attachmentsUpdateConverter.toDb(attachments),
        };
      },
      invalidatesTags: ['Ticket'],
    }),
    deleteTicketAttachment: build.mutation<
      void,
      {
        ticketId: string;
        attachmentId: string;
      }
    >({
      query({ ticketId, attachmentId }) {
        return {
          url: `${TICKETS_BASE_URL}/${ticketId}/attachments/${attachmentId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Ticket'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTicketCommentsQuery,
  useSaveTicketCommentMutation,
  useEditTicketCommentMutation,
  useDeleteTicketCommentByIdMutation,
  useCreateTicketMutation,
  useGetAllTicketsQuery,
  useGetTicketQuery,
  useUpdateTicketAttachmentsMutation,
  useDeleteTicketAttachmentMutation,
  useMarkUsReadTicketCommentMutation,
} = ticketsApi;
