import React, { Suspense, FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { useAppSelector } from '../redux/store';

const RoutesPage: FC = () => {
  const user = useAppSelector(state => state.authSlice.user);
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/*" element={<PublicRoutes isLoggedIn={!!user} />} />
        <Route path="app/*" element={<PrivateRoutes isLoggedIn={!!user} />} />
      </Routes>
    </Suspense>
  );
};
export default RoutesPage;
