import { Colors } from 'core/CssVariables';
import { TAuthConverter, TFetchConverter } from 'data/types/converter.types';
import {
  TMaterial,
  TMaterialMapperColor,
  TMaterialPostData,
  TMaterialPostDataUi,
  TMaterialStatus,
  TMaterialStatusUi,
  TMaterialTotals,
  TMaterialTotalsUi,
  TMaterialUi,
} from 'data/types/material.type';
import { currencyFormatter } from 'helpers/utils';
import { getCategoryConverter } from './category.converter';

export const materialStatusConverter: TFetchConverter<
  TMaterialStatusUi,
  TMaterialStatus
> = {
  fromDb: data => {
    const status: { [key in TMaterialStatus]: TMaterialStatusUi } = {
      [TMaterialStatus.NEW]: TMaterialStatusUi.NEW,
      [TMaterialStatus.QUOTED]: TMaterialStatusUi.QUOTED,
      [TMaterialStatus.QUOTE_SENT]: TMaterialStatusUi.QUOTE_SENT,
      [TMaterialStatus.QUOTE_APPROVED]: TMaterialStatusUi.QUOTE_APPROVED,
      [TMaterialStatus.INVOICED]: TMaterialStatusUi.INVOICED,
      [TMaterialStatus.INVOICE_EXPORTED]: TMaterialStatusUi.INVOICE_EXPORTED,
      [TMaterialStatus.INVOICE_SENT]: TMaterialStatusUi.INVOICE_SENT,
      [TMaterialStatus.INVOICE_PAID]: TMaterialStatusUi.INVOICE_PAID,
      [TMaterialStatus.PRODUCT_INSTALLED]: TMaterialStatusUi.PRODUCT_INSTALLED,
      [TMaterialStatus.PRODUCT_ORDERED]: TMaterialStatusUi.PRODUCT_ORDERED,
      [TMaterialStatus.PRODUCT_RECEIVED]: TMaterialStatusUi.PRODUCT_RECEIVED,
    };
    return status[data];
  },
};
export const materialConverter: TAuthConverter<
  TMaterialPostDataUi,
  TMaterialPostData,
  TMaterialUi,
  TMaterial
> = {
  toDb: (data: TMaterialPostDataUi): TMaterialPostData => {
    const convertedObj: TMaterialPostData = {
      cost: data.cost,
      name: data.name,
      price: data.price,
      quantity: data.quantity,
      categoryId: data.categoryId,
      ...(data.description && { description: data.description }),
    };
    return convertedObj;
  },
  fromDb: (data: TMaterial): TMaterialUi => {
    const priceString = currencyFormatter(data.price);
    const costString = currencyFormatter(data.cost);
    return {
      id: data.id,
      cost: data.cost,
      name: data.name,
      price: data.price,
      quantity: data.quantity,
      status: data.status,
      costString,
      priceString,
      totalCost: currencyFormatter(data.totalCost),
      totalPrice: currencyFormatter(data.totalPrice),
      category: data.category && getCategoryConverter.fromDb(data.category),
      description: data.description,
      possibleStatuses:
        data.possibleStatuses &&
        data.possibleStatuses.map(status =>
          materialStatusConverter.fromDb(status),
        ),
    };
  },
};
export const materialTotalsConverter: TFetchConverter<
  TMaterialTotalsUi,
  TMaterialTotals
> = {
  fromDb: data => {
    return {
      materialsTotalCost: currencyFormatter(data.materialsTotalCost),
      materialsTotalPrice: currencyFormatter(data.materialsTotalPrice),
    };
  },
};

export const materialStatusMapperColor: TMaterialMapperColor = {
  [TMaterialStatusUi.NEW]: Colors.LightGrey,
  [TMaterialStatusUi.QUOTE_SENT]: Colors.WarningColor,
  [TMaterialStatusUi.QUOTE_APPROVED]: Colors.DarkPurple,
  [TMaterialStatusUi.QUOTED]: Colors.Grey,
  [TMaterialStatusUi.INVOICED]: Colors.DarkBlue,
  [TMaterialStatusUi.INVOICE_SENT]: Colors.Pink,
  [TMaterialStatusUi.INVOICE_PAID]: Colors.SuccessColor,
  [TMaterialStatusUi.INVOICE_EXPORTED]: Colors.SecondaryColor,
  [TMaterialStatusUi.PRODUCT_ORDERED]: Colors.Orange,
  [TMaterialStatusUi.PRODUCT_RECEIVED]: Colors.PrimaryColor,
  [TMaterialStatusUi.PRODUCT_INSTALLED]: Colors.InfoColor,
};
