import {
  TContactShortData,
  TContactShortDataUi,
  TDevicesShortData,
  TDevicesShortDataUi,
} from './shortData.type';
import {
  TAttachment,
  TAttachmentUi,
  TGetAttachment,
  TGetAttachmentUi,
} from './generalDataTypes';
import { Colors } from '../../core/CssVariables';
import {
  TMaterial,
  TMaterialTotals,
  TMaterialTotalsUi,
  TMaterialUi,
} from './material.type';

export type TTicketPriorityMapperColor = {
  [key in Priority]: Colors;
};

export type TTicketStatusMapperColor = {
  [key in TTicketStatusUi]: Colors;
};

export type TPriorityColorsMap = {
  [key in Priority]: Colors;
};
export type TTimeWrapper = {
  time: string;
};

export enum Priority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Urgent = 'Urgent',
}

export type TTicketSpecialFlagsUi = {
  invoicePaid?: boolean;
  invoiceSent?: boolean;
  overdueSince: string | null;
  unreadComments: number;
  rmmAlertResolved: null | boolean;
};
export type TTicketSpecialFlags = {
  invoicePaid?: boolean;
  invoiceSent?: boolean;
  overdueSince: string | null;
  unreadComments: number;
  rmmAlertResolved: null | boolean;
};

export type TTicket = {
  id: string;
  number: string;
  title: string;
  description: string;
  status: TTicketStatus;
  priority: string;
  creationDateTime: string;
  completionDateTime: string;
  assignee?: TAssignee;
  htmlContent?: string;
  reporter?: TTicketReporter;
};
export type TTicketUi = {
  id: string;
  number: string;
  title: string;
  description: string;
  status: TTicketStatusUi;
  priority: string;
  creationDateTime: string;
  creationDate: string;
  completionDateTime: string;
  assignee?: TAssigneeUi;
  htmlContent?: string;
  reporter?: TTicketReporterUi;
};

export type TicketRelation = {
  id: string;
  name: string;
};

export type TAssigneeUi = {
  name: string;
  photoUrl: string | null;
} | null;

export type TAssignee = {
  name: string;
  photoUrl: string | null;
} | null;

export type TCreateTicketUi = {
  title: string;
  description: string;
  status: string;
  priority: string;
  queueId: string;
  issueTypeId: string;
  issueSubTypeId: string;
  assigneeId: string;
  companyId: string;
  reporterId: string;
  deviceId: string;
  attachments: TAttachmentUi[];
};

export type TCreateTicket = {
  title: string;
  description: string;
  status: string;
  priority: string;
  queueId: string;
  issueTypeId: string;
  issueSubTypeId: string;
  assigneeId: string;
  companyId: string;
  reporterId: string;
  deviceId: string;
  attachments: TAttachment[];
};

export type TAllUnassignedTickets = {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: TTicket[];
};
export type TAllUnassignedTicketsUi = {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: TTicketUi[];
};

export type TTicketAssignedUi = {
  id: string;
  name: string;
  photoUrl: string;
};
export type TTicketAssigned = {
  id: string;
  name: string;
  photoUrl: string;
};

export enum TTicketStatus {
  Open = 'Open',
  Completed = 'Completed',
  Closed = 'Closed',
  New = 'New',
  Assigned = 'Assigned',
  Scheduled = 'Scheduled',
  CustomerNoteAdded = 'CustomerNoteAdded',
  InProgress = 'InProgress',
  Escalate = 'Escalate',
  WaitingParts = 'WaitingParts',
  WaitingCustomer = 'WaitingCustomer',
  WaitingVendor = 'WaitingVendor'
}

export enum TTicketStatusUi {
  Open = 'Open',
  Completed = 'Completed',
  Closed = 'Closed',
  New = 'New',
  Assigned = 'Assigned',
  Scheduled = 'Scheduled',
  CustomerNoteAdded = 'Customer Note Added',
  InProgress = 'In Progress',
  Escalate = 'Escalate',
  WaitingParts = 'Waiting Parts',
  WaitingCustomer = 'Waiting Customer',
  WaitingVendor = 'Waiting Vendor'
}

export type TTicketCompanyUi = {
  id: string;
  logoUrl: string;
  name: string;
  mainContact: TContactShortDataUi;
  firstLetters: string;
  phoneNumber: string;
  shortName: string;
  nameWithAbbreviation: string;
};
export type TTicketCompany = {
  id: string;
  logoUrl: string;
  name: string;
  mainContact: TContactShortData;
  phoneNumber: string;
  shortName: string;
};

export type TChecklistItem = {
  id: string;
  description: string;
  parentId: string;
  creationDateTime: string;
  creator: TCheckListItemCreatorUi;
  isChecked: boolean;
};
export type TChecklistItemUi = {
  id: string;
  description: string;
  parentId: string;
  creationDateTime: string;
  creator: TCheckListItemCreatorUi;
  isDone: boolean;
};
export type TCheckListItemCreator = {
  id: string;
  name: string;
  photoUrl: string;
};
export type TCheckListItemCreatorUi = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TTicketAction = {
  action: TicketActionEnum;
  nextPossibleStatuses: TTicketStatus[];
};
export type TTicketActionUi = {
  action: TicketActionEnum;
  nextPossibleStatuses: TTicketStatus[];
};
export type TTicketReporterUi = {
  email: string;
  name: string;
};
export type TTicketReporter = {
  name: string;
  email: string;
};
export type TTicketDetailsUi = {
  ticket: TTicketUi;
  attachments: TGetAttachmentUi[];
  company: TTicketCompanyUi;
  checklistItems: TChecklistItemUi[];
  actions: TTicketActionUi[];
  reporter?: TTicketReporterUi;
  materials: TMaterialUi[];
  materialTotals: TMaterialTotalsUi;
  device: TDevicesShortDataUi;
};
export type TTicketDetails = {
  ticket: TTicket;
  attachments: TGetAttachment[];
  company: TTicketCompany;
  checklistItems: TChecklistItem[];
  actions: TTicketActionUi[];
  reporter?: TTicketReporter;
  materials: TMaterial[];
  materialTotals: TMaterialTotals;
  device: TDevicesShortData;
};

// INFO: value of ticketfilters related to columns in filter url api
export enum TicketFilters {
  QUEUE = 'queueIds',
  PRIORITY = 'priorities',
  STATUS = 'statuses',
  ASSIGNED = 'assigneeIds',
  CREATION_DATE = 'creationDate',
  REPORTER = 'reporterIds',
  ISSUE_TYPE = 'issueTypeIds',
  ISSUE_SUBTYPE = 'issueSubTypeIds',
  COMPANY = 'companyIds',
  SPECIAL_FLAGS = 'specialFlags',
  COMPILATION_DATE = 'completionDate',
}

export enum TicketFilterNames {
  'Creation_date' = 'Creation date',
  'Company' = 'Company',
  'Contact' = 'Contact',
  'Status' = 'Status',
  'Priority' = 'Priority',
  'Issue_type' = 'Issue type',
  'Issue_subtype' = 'Issue subtype',
  'Queue' = 'Queue',
  'Assignee' = 'Primary resource',
  'Special flags' = 'Special flags',
  'Resolution date' = 'Resolution date',
  'Reporter' = 'Reporter',
}

export enum AllTicketsPath {
  UNASSIGNED = 'unassigned',
  OPEN = 'open',
  CLOSE = 'closed',
  ALL = 'all',
}

// edit types  start
export type TEditTicketUi = {
  id: string;
  value: string;
};
export type TEditTicketIssueSubTypeUi = {
  id: string;
  issueTypeId: string;
  issueSubTypeId: string;
};
export type TEditTicketTitle = {
  id: string;
  value: string;
};
export type TEditTicketPriority = {
  id: string;
  value: string;
};
export type TEditTicketStatus = {
  id: string;
  status: string;
};

export type TEditTicketDescription = {
  id: string;
  value: string;
};

export type TEditTicketAssignee = {
  id: string;
  assigneeId: string;
};

export type TEditTicketCompany = {
  id: string;
  value: string;
};
export type TEditTicketQueue = {
  id: string;
  value: string;
};
export type TEditTicketIssueType = {
  id: string;
  value: string;
};
export type TEditTicketIssueSubType = {
  id: string;
  issueTypeId: string;
  issueSubtypeId: string;
};
export type TEditTicketScheduleDescription = {
  id: string;
  value: string;
};
export type TEditTicketReporter = {
  id: string;
  value: string;
};
// edit types  end

export enum TicketActionEnum {
  START_WORK = 'START_WORK',
  ASSIGN = 'ASSIGN',
  RESOLVE = 'RESOLVE',
  RE_ASSIGN = 'RE_ASSIGN',
  SCHEDULE = 'SCHEDULE',
  RE_SCHEDULE = 'RE_SCHEDULE',
  CLOSE = 'CLOSE',
  QUICK_SCHEDULE = 'QUICK_SCHEDULE',
}

export type TTicketComment = {
  id: string;
  text: string;
  creationTime: string;
  commenter: Commenter;
  ticketId: string;
  fromEmail: boolean;
  edited: boolean;
  lastModifiedTime: string;
  replyToCustomer: boolean;
  fromCustomer: boolean;
  unread: boolean;
};
export type Commenter = {
  userId: string;
  userType: string;
  fullName: string;
  photoUrl: string;
};
export type TTicketCommentUi = {
  id: string;
  text: string;
  creationTime: string;
  commenter: CommenterUi;
  ticketId: string;
  fromEmail: boolean;
  edited: boolean;
  lastModifiedTime: string;
  replyToCustomer: boolean;
  fromCustomer: boolean;
  unread: boolean;
};
export type CommenterUi = {
  userId: string;
  userType: string;
  fullName: string;
  photoUrl: string;
  firstLetters: string;
};

export type TTicketTableDefaultSortMapper = { [key in AllTicketsPath]: string };

export enum ETicketSpecialFlagsUi {
  IS_OVERDUE = 'Overdue',
  HAS_UNREAD_COMMENT = 'Unread comment',
  INVOICE_SENT = 'Invoice sent',
  INVOICE_PAID = 'Invoice paid',
  RMM_ALERT_RESOLVED = 'RMM alert resolved',
}
export enum ETicketSpecialFlags {
  IS_OVERDUE = 'IS_OVERDUE',
  HAS_UNREAD_COMMENT = 'HAS_UNREAD_COMMENT',
  INVOICE_SENT = 'INVOICE_SENT',
  INVOICE_PAID = 'INVOICE_PAID',
  RMM_ALERT_RESOLVED = 'RMM_ALERT_RESOLVED',
}

export enum TPrimaryTitleType {
  HUMAN = 'HUMAN',
  AI = 'AI',
}

export enum TPrimaryTitleTypeUI {
  HUMAN = 'HUMAN',
  AI = 'AI',
}

export enum TTitleGrade {
  BAD = 'BAD',
  GOOD = 'GOOD',
  EXCELLENT = 'EXCELLENT',
}

export enum TTitleGradeUI {
  BAD = 'BAD',
  GOOD = 'GOOD',
  EXCELLENT = 'EXCELLENT',
}
