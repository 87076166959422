import { authSplitApi } from 'redux/helpers/slice.helpers';

export const FILE_UPLOAD = 'api/files';

export const uploadFileApi = authSplitApi('uploadFilePath').injectEndpoints({
  endpoints: build => ({
    uploadFile: build.mutation({
      query(body) {
        return {
          url: FILE_UPLOAD,
          method: 'POST',
          body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useUploadFileMutation } = uploadFileApi;
