import { FC } from 'react';
import { Col, Row } from 'components/Base/Grid/Grid';
import Avatar from 'components/Base/Avatar/Avatar';
import { Text } from 'components/Base/Typography';
import { GetFirstLetters } from 'data/converters/general.converters';

type TOrganizationInfoProps = {
  avatarUrl: string | null;
  name: string;
  className?: string;
  size?: 'large' | 'small' | 'default';
};

const OrganizationInfo: FC<TOrganizationInfoProps> = ({
  avatarUrl,
  name,
  className,
  size,
}) => {
  return (
    <Row gutter={[8, 0]} wrap={false} className={className} align="middle">
      <Col>
        <Avatar size={size || 'default'} src={avatarUrl}>
          {!avatarUrl && GetFirstLetters.fromDb(name)}
        </Avatar>
      </Col>
      <Col xs={0} sm={20} xxl={20}>
        <Text fontlevel={5}>{name}</Text>
      </Col>
    </Row>
  );
};

export default OrganizationInfo;
