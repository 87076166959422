import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { ELoginFlow } from '../../data/types/user.types';
import { cognitoProvider } from '../../helpers/CustomAuth';

const baseUrl = process.env.REACT_APP_BASE_BACKEND_URL;

export const authSplitApi = (reducerPath: string, tagTypes?: string[]) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl,
      prepareHeaders: async headers => {
        let accessToken = null;
        const loginFlow = localStorage?.getItem('loginFlow')
          ? (JSON.parse(
              localStorage.getItem('loginFlow') as string,
            ) as ELoginFlow)
          : null;
        switch (loginFlow) {
          case ELoginFlow.PASSWORD_LESS: {
            accessToken = await cognitoProvider.getAccessToken();
            break;
          }
          case ELoginFlow.WITH_PASSWORD: {
            const session = await Auth.currentSession();
            accessToken = session.getIdToken().getJwtToken();
            break;
          }
          default:
            accessToken = null;
        }

        if (accessToken) {
          headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
      },
    }),
    tagTypes: tagTypes || [],
    endpoints: () => ({}),
  });

export const emptySplitApi = (reducerPath: string) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl,
    }),
    endpoints: () => ({}),
  });
