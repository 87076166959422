import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import React, { Suspense, useEffect, FC } from 'react';
import { privateRoutes } from './RoutesData';
import { RoutesPaths } from './Routes.types';
import Layout from '../components/Base/Layout/Layout';
import { useGetUserQuery } from '../redux/apiSlice/user.slices';
import { removeUser, setUser } from '../redux/slices/auth.slice';
import { useAppDispatch } from '../redux/store';

const PrivateRoutes: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const { data: user, isError } = useGetUserQuery();
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  if (user?.organizationLogoUrl && favicon) {
    favicon.href = user?.organizationLogoUrl;
  }
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    } else if (isError) {
      dispatch(removeUser());
    }
  }, [user, isError]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/portal');
    }
  }, [isLoggedIn]);

  if (!user) {
    return null;
  }
  return (
    <Suspense fallback={<div>Loading ...</div>}>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="*"
            element={<Navigate replace to={RoutesPaths.ALL_TICKETS} />}
          />
          {privateRoutes.map(({ path, component }) => {
            const Component = component;
            return (
              <Route key={path} path={`${path}/*`} element={<Component />} />
            );
          })}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default PrivateRoutes;
