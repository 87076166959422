import { authSplitApi } from '../helpers/slice.helpers';

const testUrl = 'cp/foo/bar';

export const testApi = authSplitApi('testApi', ['data']).injectEndpoints({
  endpoints: build => ({
    getData: build.query<void, void>({
      query() {
        return {
          url: `${testUrl}/data`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useLazyGetDataQuery } = testApi;
