import { ButtonType } from 'antd/es/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import Button from 'components/Base/Button/Button';
import { TButtonTypes } from 'components/Base/Button/Button.types';
import { Icon, TIconNames } from 'components/Base/Icon';
import { Menu, MenuItem } from 'components/Base/Menu/Menu';
import { Colors } from 'core/CssVariables';
import React from 'react';
import { SDropdown } from './Dropdown.style';
import { TDropdownItems, TDropDownProp } from './Dropdown.type';

const Dropdown: React.FC<TDropDownProp> = props => {
  return <SDropdown {...props} />;
};

const DropdownMenuItem: React.FC<{
  onClickItem: (item: any) => void;
  buttonTitle?: string;
  buttonIcon?: JSX.Element;
  buttonType?: ButtonType;
  items: TDropdownItems[];
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  children?: React.ReactNode;
  buttonDownIcon?: boolean;
  buttonColor?: TButtonTypes;
  buttonSize?: SizeType;
  buttonClassName?: string;
  disabled?: boolean;
}> = props => {
  const {
    onClickItem,
    items,
    trigger,
    buttonTitle,
    buttonIcon,
    buttonType,
    children,
    buttonDownIcon,
    buttonColor,
    buttonSize,
    buttonClassName,
    disabled,
  } = props;
  return (
    <Dropdown
      disabled={disabled}
      className="customScroll"
      trigger={trigger}
      overlay={
        <Menu>
          {items.map(item => (
            <MenuItem
              key={item.key}
              className="menu-item-ticket-filter"
              onClick={e => {
                e.domEvent.stopPropagation();
                onClickItem(item.key);
              }}
            >
              <div>
                {item.icon && (
                  <Icon icon={item.icon} size={20} className="mr-2" />
                )}
                {item.name}
              </div>
              {item.selected && (
                <Icon
                  icon={TIconNames.CHECK_ROUNDED}
                  size={16}
                  color={Colors.SuccessColor}
                />
              )}
            </MenuItem>
          ))}
        </Menu>
      }
    >
      {children || (
        <Button
          type={buttonType || 'link'}
          className={buttonClassName}
          {...(buttonSize && { size: buttonSize })}
          {...(buttonColor && { color: buttonColor })}
          {...(buttonIcon && { icon: buttonIcon })}
        >
          {buttonTitle || 'Title'}{' '}
          {buttonDownIcon && (
            <Icon
              icon={TIconNames.ARROW_DROPDOWN}
              size={16}
              className="ml-3 mr-0"
            />
          )}
        </Button>
      )}
    </Dropdown>
  );
};

export { Dropdown, DropdownMenuItem };
