import { Tabs } from "antd";
import { TTicketStatus } from "data/types/ticket.types";
import { lazy } from "react";

const TicketsTabs = () => {
    
    const TicketsList = lazy(
      () => import('pages/private/Tickets/TicketsList/TicketsList'),
    );
    
    return (
        <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Open Tickets" key="1">
            <TicketsList status={TTicketStatus.Open}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Closed Tickets" key="2">
            <TicketsList status={TTicketStatus.Closed}/>
            </Tabs.TabPane>
        </Tabs>
      );
}

export default TicketsTabs;