import AntdLayout from 'antd/es/layout';
import styled from 'styled-components';
import { LineHeights } from 'core/CssVariables';

const { Header: AntdHeader } = AntdLayout;

const SHeader = styled(AntdHeader)`
  & {
    height: fit-content;
    background-color: ${props => props.theme.secondaryBackgroundColor};
    color: ${props => props.theme.color};
    padding: 8px 12px;
    line-height: ${LineHeights.ExtraShort};
    user-select: none;
    .header-expand-icon {
      transform: rotate(90deg);
    }
  }
`;

export default SHeader;
