import { Outlet } from 'react-router-dom';
import { SContent, SLayout } from './Layout.styles';
import Header from './Header/Header';

const Layout = () => {
  return (
    <SLayout>
      <Header />
      <SContent>
        <Outlet />
      </SContent>
    </SLayout>
  );
};
export default Layout;
