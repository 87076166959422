/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
//
// const awsmobile = {
//   aws_project_region: process.env.REACT_APP_BUCKET_REGION,
//   aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_ID,
//   aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
//   aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
//   aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
//   aws_user_files_s3_bucket: process.env.REACT_APP_BUCKET_NAME,
//   aws_user_files_s3_bucket_region: process.env.REACT_APP_BUCKET_REGION,
// };

const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      // redirectSignIn: 'http://localhost:3000/',
      // redirectSignOut: 'http://localhost:3000/',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

export default amplifyConfig;
