import { useAppSelector } from 'redux/store';
import { useLocation } from 'react-router-dom';
import SHeader from './Header.styles';
import PrivateHeader from './PrivateHeader/PrivateHeader';
import PublicHeader from './PublicHeader/PublicHeader';

const Header = () => {
  const location = useLocation();
  const user = useAppSelector(state => state.authSlice.user);

  if (location.pathname === '/login') {
    return null;
  }
  return (
    <SHeader className="header">
      {user ? <PrivateHeader /> : <PublicHeader />}
    </SHeader>
  );
};
export default Header;
