import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ELoginFlow, TAuthUserUi } from 'data/types/user.types';

export type TAuthSliceState = {
  user: null | TAuthUserUi;
  loginFlow: ELoginFlow | null;
};

const initialState: TAuthSliceState = {
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : null,
  loginFlow: localStorage.getItem('loginFlow')
    ? JSON.parse(localStorage.getItem('loginFlow') as string)
    : null,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<TAuthUserUi>) {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    removeUser(state) {
      state.user = null;
      state.loginFlow = null;
      localStorage.removeItem('user');
      localStorage.removeItem('loginFlow');
      localStorage.clear();
    },
    setLoginFlow(
      state,
      action: PayloadAction<{
        loginFlow: ELoginFlow;
      }>,
    ) {
      state.loginFlow = action.payload.loginFlow;
      localStorage.setItem(
        'loginFlow',
        JSON.stringify(action.payload.loginFlow),
      );
    },
  },
});

export const { setUser, removeUser, setLoginFlow } = authSlice.actions;
