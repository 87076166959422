import styled from 'styled-components';
import AntdLayout from 'antd/es/layout';
import { Screens } from 'core/CssVariables';

const { Sider: AntdSider, Content: AntdContent } = AntdLayout;

export const SSlider = styled(AntdSider)`
  background-color: ${props => props.theme.sidebar.menuBackgroundColor};
  height: 100vh;
  overflow: auto;
  .sidebar-switcher {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 1;
  }
  @media (max-width: ${Screens.ScreensMD}) {
    position: fixed;
    z-index: 2;
  }
`;

export const SContentLayout = styled(AntdLayout)`
  height: 100%;
  background-color: ${props => props.theme.backgroundColor};
  @media (max-width: ${Screens.ScreensMD}) {
    margin-left: 0;
  }
`;

export const SContent = styled(AntdContent)`
  padding: 0 16px;
`;

export const SLayout = styled(AntdLayout)`
  overflow: hidden;
  background-color: ${props => props.theme.backgroundColor};
  height: 100vh;
`;
