import React, { FC } from 'react';
import { Icon, TIconNames } from 'components/Base/Icon';
import { useNavigate } from 'react-router-dom';
import { RoutesPaths } from 'routes/Routes.types';
import DashbarDropDownMenu from 'components/Base/DashbarDropDownMenu/DashbarDropDownMenu';
import { ELoginFlow } from 'data/types/user.types';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { removeUser } from 'redux/slices/auth.slice';
import { cognitoProvider } from 'helpers/CustomAuth';
import { signAmplifySignOut } from 'api/services/authServices';
import { SProfileWrapper } from './ProfileMenu.style';
import Avatar from '../../../../Avatar/Avatar';
import { GetFirstLetters } from '../../../../../../data/converters/general.converters';

type TProfileMenuProps = {
  isAccessLimited?: boolean;
};

const profileMenuItems = [
  {
    title: 'Profile',
    actionType: RoutesPaths.PROFILE,
    icon: TIconNames.PROFILE,
  },
  {
    title: 'Logout',
    actionType: 'logout',
    icon: TIconNames.LOGOUT,
  },
];

const ProfileMenu: FC<TProfileMenuProps> = ({ isAccessLimited }) => {
  const navigate = useNavigate();
  const { loginFlow, user } = useAppSelector(state => state.authSlice);
  const dispatch = useAppDispatch();
  const logout = async () => {
    if (loginFlow === ELoginFlow.PASSWORD_LESS) {
      dispatch(removeUser());
      cognitoProvider.signOut();
    }
    signAmplifySignOut().then(() => {
      dispatch(removeUser());
      navigate('/login');
    });
  };

  const handleItemClick = (actionType: string) => {
    switch (actionType) {
      case RoutesPaths.PROFILE:
        return navigate(RoutesPaths.PROFILE);
      case 'logout':
        return logout();

      default:
        return () => {
          return null;
        };
    }
  };
  const menuItems = isAccessLimited
    ? profileMenuItems.filter(item => item.actionType === 'logout')
    : profileMenuItems;
  return (
    <DashbarDropDownMenu menuItems={menuItems} onClick={handleItemClick}>
      <SProfileWrapper className="pointer mr-3">
        {user?.name ? (
          <Avatar src="" size="default">
            {GetFirstLetters.fromDb(user.name)}
          </Avatar>
        ) : (
          <Icon icon={TIconNames.PERSON} size={32} />
        )}
      </SProfileWrapper>
    </DashbarDropDownMenu>
  );
};

export default ProfileMenu;
