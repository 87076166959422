export enum EUserRoles {
  CONTACT = 'Contact',
  SUPERVISOR = 'Supervisor',
}
export enum ELoginFlow {
  PASSWORD_LESS = 'PASSWORD_LESS',
  WITH_PASSWORD = 'WITH_PASSWORD',
}
export type TAuthUserUi = {
  id: string;
  email: string;
  role: EUserRoles;
  companyLogoUrl: string | null;
  companyName: string;
  organizationLogoUrl: string | null;
  organizationName: string;
  organizationWebsite: string | null;
  timeZone: string;
  name?: string | null;
  phone?: string | null;
};

export type TAuthUser = {
  id: string;
  email: string;
  role: EUserRoles;
  companyLogoUrl: string | null;
  companyName: string;
  organizationLogoUrl: string | null;
  organizationName: string;
  organizationWebsite: string | null;
  timeZone: string;
  name?: string | null;
  phone?: string | null;
};

export enum TUserRoleUi {
  Admin = 'Admin',
  Technician = 'Technician',
  Owner = 'Owner',
}
export enum TUserRole {
  Admin = 'Admin',
  Technician = 'Technician',
  Owner = 'Owner',
}

export type TGroup = {
  id: string;
  name: string;
  membersCount: number;
  members: string[];
};
export type TGroupUi = {
  id: string;
  name: string;
  membersCount: number;
  members: string[];
};
export type TMember = {
  id: string;
  role: TUserRole;
  email: string;
  fullName: string;
  photoUrl: string;
  groups: TGroup[];
  isDeleted: boolean;
  hourlyRate?: string;
};
export type TMemberUi = {
  id: string;
  role: TUserRoleUi;
  email: string;
  fullName: string;
  photoUrl: string;
  groups: TGroupUi[];
  isDeleted: boolean;
  userGroupsString?: string;
  hourlyRate?: string;
};
