import { lazy } from 'react';
import TicketsTabs from 'pages/private/Tickets/TicketsTabs/TicketsTabs';
import { PrivateRouteType, PublicRouteType, RoutesPaths } from './Routes.types';

const SubmitTicket = lazy(
  () => import('pages/public/SubmitTicket/SubmitTicket'),
);
const PageNotFound = lazy(() => import('pages/private/PageNotFound'));

const TicketDetail = lazy(
  () => import('pages/private/Tickets/TicketDetail/TicketDetail'),
);
const Profile = lazy(() => import('pages/private/Profile/Profile'));
const Login = lazy(() => import('pages/public/Login/Login'));
const VerifyAuth = lazy(() => import('pages/public/VerifyAuth/VerifyAuth'));

export const publicRoutes: PublicRouteType[] = [
  {
    component: SubmitTicket,
    path: 'portal',
  },
  {
    component: VerifyAuth,
    path: 'verify-auth',
  },
  {
    component: Login,
    path: 'login',
  },
];

export const privateRoutes: PrivateRouteType[] = [
  {
    title: 'Tickets',
    component: TicketsTabs,
    path: RoutesPaths.ALL_TICKETS,
    show: false,
  },
  {
    title: 'Ticket Detail',
    component: TicketDetail,
    path: RoutesPaths.TICKET_DETAIL,
    show: false,
  },
  {
    title: 'Profile',
    component: Profile,
    path: RoutesPaths.PROFILE,
    show: false,
  },
  {
    title: '404',
    component: PageNotFound,
    path: RoutesPaths.PAGE_NOT_FOUND,
    show: false,
  },
];
