import { TFetchConverter } from 'data/types/converter.types';
import { TPageableData, TPageableDataUi } from 'data/types/pagableData.type';

export const pagableDataConverter: TFetchConverter<
  TPageableDataUi,
  TPageableData
> = {
  fromDb: data => {
    return {
      totalElements: data.totalElements,
      totalPages: data.totalPages,
      pageable: data.pageable,
      sort: data.sort,
      first: data.first,
      last: data.last,
      number: data.number,
      numberOfElements: data.numberOfElements,
      size: data.size,
      empty: data.empty,
    };
  },
};
