import { Colors } from 'core/CssVariables';
import { TCategory, TCategoryUi } from './category.types';

export enum TMaterialStatus {
  NEW = 'New',
  QUOTED = 'Quoted',
  QUOTE_SENT = 'Quote sent',
  QUOTE_APPROVED = 'Quote approved',
  INVOICED = 'Invoiced',
  INVOICE_SENT = 'Invoice sent',
  INVOICE_PAID = 'Invoice paid',
  INVOICE_EXPORTED = 'Invoice exported',
  PRODUCT_ORDERED = 'Product ordered',
  PRODUCT_RECEIVED = 'Product received',
  PRODUCT_INSTALLED = 'Product installed',
}
export enum TMaterialStatusUi {
  NEW = 'New',
  QUOTED = 'Quoted',
  QUOTE_SENT = 'Quote sent',
  QUOTE_APPROVED = 'Quote approved',
  INVOICED = 'Invoiced',
  INVOICE_SENT = 'Invoice sent',
  INVOICE_PAID = 'Invoice paid',
  INVOICE_EXPORTED = 'Invoice exported',
  PRODUCT_ORDERED = 'Product ordered',
  PRODUCT_RECEIVED = 'Product received',
  PRODUCT_INSTALLED = 'Product installed',
}

export type TMaterial = {
  id: string;
  name: string;
  status: string;
  cost: number;
  price: number;
  quantity: number;
  totalCost: number;
  totalPrice: number;
  category?: TCategory;
  description?: string;
  possibleStatuses: TMaterialStatus[];
};
export type TMaterialUi = {
  id: string;
  name: string;
  status: string;
  cost: number;
  price: number;
  quantity: number;
  priceString: string;
  costString: string;
  totalCost: string;
  totalPrice: string;
  category?: TCategoryUi;
  description?: string;
  possibleStatuses: TMaterialStatusUi[];
};

export type TMaterialPostData = {
  name: string;
  cost: number;
  price: number;
  quantity: number;
  categoryId?: string;
  description?: string;
};
export type TMaterialPostDataUi = {
  name: string;
  cost: number;
  price: number;
  quantity: number;
  categoryId?: string;
  description?: string;
};

export type TMaterialTotalsUi = {
  materialsTotalCost: string;
  materialsTotalPrice: string;
};
export type TMaterialTotals = {
  materialsTotalCost: number;
  materialsTotalPrice: number;
};

export type TMaterialMapperColor = {
  [key in TMaterialStatusUi]: Colors;
};
