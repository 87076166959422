import { Auth } from 'aws-amplify';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { TAmplifyLoginData, TLoginDataUi } from '../../data/types/auth.types';
import loginAmplifyConverter from '../../data/converters/auth.converters';

export const loginAmplifyService = async (
  dependancy: TLoginDataUi,
): Promise<TAmplifyLoginData> => {
  const { email, password } = loginAmplifyConverter.toDb(dependancy);
  return Auth.signIn(email, password);
};

export const signAmplifySignOut = async (): Promise<null> => {
  return Auth.signOut();
};

export const getCurrentSession = async (): Promise<CognitoUserSession> => {
  return Auth.currentSession();
};
