import { authSplitApi } from 'redux/helpers/slice.helpers';
import { TAuthUser, TAuthUserUi } from 'data/types/user.types';
import { userConverter } from 'data/converters/user.converter';

const USERS_BASE_URL = 'api/cp/users';

const domain = window.location.hostname;
export const getUserApi = authSplitApi('authUser', ['User']).injectEndpoints({
  endpoints: build => ({
    getUser: build.query<TAuthUserUi, void>({
      query() {
        return {
          url: USERS_BASE_URL,
          method: 'GET',
          params: {
            cpDomain: domain,
          },
        };
      },
      providesTags: ['User'],
      keepUnusedDataFor: 60 * 60 * 24,
      transformResponse: (data: TAuthUser) => userConverter.fromDb(data),
    }),
    changeTheme: build.mutation<void, { resourceId: string; theme: string }>({
      query({ resourceId, theme }) {
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: USERS_BASE_URL.concat(`/${resourceId}/theme`),
          method: 'PATCH',
          body: { value: theme },
        };
      },
    }),
    setPassword: build.mutation<
      void,
      {
        password: string;
      }
    >({
      query({ password }) {
        return {
          url: `${USERS_BASE_URL}/set-password`,
          method: 'PATCH',
          body: {
            value: password,
          },
          params: {
            cpDomain: domain,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserQuery,
  useChangeThemeMutation,
  useLazyGetUserQuery,
  useSetPasswordMutation,
} = getUserApi;
