import AWS, { CognitoIdentityServiceProvider } from 'aws-sdk';

import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { ETokensEnum, TInitCustomSessionParams } from './CustomAuth.types';

class CustomAuth {
  private provider: CognitoIdentityServiceProvider;

  constructor() {
    this.provider = new AWS.CognitoIdentityServiceProvider({
      region: process.env.REACT_APP_COGNITO_REGION,
    });
  }

  public initCustomSession({
    session,
    code,
    email,
    onSuccess,
    onFail,
  }: TInitCustomSessionParams) {
    if (process.env.REACT_APP_COGNITO_WEB_CLIENT_ID) {
      this.provider.respondToAuthChallenge(
        {
          ChallengeName: 'CUSTOM_CHALLENGE',
          ClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
          Session: session,
          ChallengeResponses: {
            ANSWER: code,
            USERNAME: email,
          },
        },
        (err, data) => {
          if (!err && data?.AuthenticationResult) {
            const { AccessToken, IdToken, RefreshToken } =
              data.AuthenticationResult;
            if (AccessToken && IdToken && RefreshToken) {
              localStorage.setItem(ETokensEnum.ID_TOKEN, IdToken);
              localStorage.setItem(ETokensEnum.ACCESS_TOKEN, AccessToken);
              localStorage.setItem(ETokensEnum.REFRESH_TOKEN, RefreshToken);
              if (onSuccess) {
                onSuccess();
              }
            }
          } else if (onFail) {
            onFail('Invalid session');
          }
        },
      );
    }
  }

  private updateTokens() {
    return new Promise(resolve => {
      const refreshToken = localStorage.getItem(ETokensEnum.REFRESH_TOKEN);
      if (refreshToken) {
        if (process.env.REACT_APP_COGNITO_WEB_CLIENT_ID)
          this.provider.initiateAuth(
            {
              AuthFlow: 'REFRESH_TOKEN_AUTH',
              ClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
              AuthParameters: {
                REFRESH_TOKEN: refreshToken,
              },
            },
            (err, newAuthData) => {
              const { AccessToken, IdToken, RefreshToken } =
                newAuthData?.AuthenticationResult || {};
              if (!err) {
                if (IdToken)
                  localStorage.setItem(ETokensEnum.ID_TOKEN, IdToken);
                if (RefreshToken)
                  localStorage.setItem(ETokensEnum.REFRESH_TOKEN, RefreshToken);
                if (AccessToken)
                  localStorage.setItem(ETokensEnum.ACCESS_TOKEN, AccessToken);
              } else if (err?.statusCode === 400 || err?.statusCode === 401) {
                localStorage.clear();
              }
              resolve('end of callback');
            },
          );
      }
    });
  }

  public async getAccessToken() {
    const accessTokenStr = localStorage.getItem(ETokensEnum.ACCESS_TOKEN);
    if (accessTokenStr) {
      const accessToken = new CognitoAccessToken({
        AccessToken: accessTokenStr,
      });
      if (Date.now() > accessToken.getExpiration() * 1000 - 10000) {
        await this.updateTokens();
      }
    }
    return localStorage.getItem(ETokensEnum.ACCESS_TOKEN);
  }

  public signOut = () => {
    localStorage.removeItem(ETokensEnum.ID_TOKEN);
    localStorage.removeItem(ETokensEnum.ACCESS_TOKEN);
    localStorage.removeItem(ETokensEnum.REFRESH_TOKEN);
  };
}

const cognitoProvider = new CustomAuth();

export { cognitoProvider };
