import {
  TFetchConverter,
  TCreateConverter,
  TConverter,
} from 'data/types/converter.types';
import {
  EStatus,
  EStatusUi,
  TAttachment,
  TAttachmentUi,
  TGetAttachment,
  TGetAttachmentUi,
  TOptionType,
  TUpdateAttachment,
  TUpdateAttachmentUi,
} from 'data/types/generalDataTypes';

export const selectOptionGetConverter: TFetchConverter<
  TOptionType,
  { name: string; id: string }
> = {
  fromDb: data => {
    return {
      value: data.id,
      label: data.name,
    };
  },
};

export const selectOptionCreateConverter: TCreateConverter<
  TOptionType,
  string
> = {
  toDb: data => {
    return data.value;
  },
};

export const DateConverter: TFetchConverter<string, number> = {
  fromDb: data => {
    const date = new Date(data * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date
      .getFullYear()
      .toString()}`;
  },
};
export const convertMinsToHrsMins: TFetchConverter<string, number> = {
  fromDb: minutes => {
    const h = Math.floor(minutes / 3600);
    const m = Math.floor((minutes % 3600) / 60);

    const hDisplay = h > 0 ? `${h > 1 ? `${h} hrs` : `${h} h`} ` : '';
    const mDisplay = m > 0 ? `${m} min` : '';
    return hDisplay + mDisplay;
  },
};
export const TimeConverter: TFetchConverter<string, number> = {
  fromDb: data => {
    // when backend implement FR SLA and REZ SLA time, we should adjust logic
    return `${data}m`;
  },
};

export const phoneNumberConverter: TConverter<string, string> = {
  toDb: phoneNumber => {
    return `+1${phoneNumber.replace(/[\s)(_-]/g, '')}`;
  },
  fromDb: phoneNumber => {
    const newNumber = phoneNumber.startsWith('+1')
      ? phoneNumber.slice(2)
      : phoneNumber;
    const x = newNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (!x) return '';
    return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
  },
};

export const GetFirstLetters: TFetchConverter<string, string> = {
  fromDb: data => {
    if (data) {
      const matches = data.match(/\b(\w)/g);
      const acronym = matches?.join('') || '';
      return acronym;
    }
    return '';
  },
};

export const attachmentConverter: TCreateConverter<TAttachmentUi, TAttachment> =
  {
    toDb: data => {
      return {
        name: data.name,
        fileUrl: data.url,
      };
    },
  };

export const attachmentsConverter: TCreateConverter<
  TAttachmentUi[],
  TAttachment[]
> = {
  toDb: data => {
    const convertedArr: TAttachment[] = [];
    data.forEach(item => {
      if (item.url) {
        convertedArr.push(attachmentConverter.toDb(item));
      }
    });
    return convertedArr;
  },
};

export const attachmentGetConverter: TFetchConverter<
  TGetAttachmentUi,
  TGetAttachment
> = {
  fromDb: data => {
    return {
      id: data.id,
      uid: data.id,
      name: data.name,
      status: 'done',
      url: data.fileUrl,
      thumbUrl: data.fileUrl,
      type: data.extension,
    };
  },
};

export const attachmentsGetConverter: TFetchConverter<
  TGetAttachmentUi[],
  TGetAttachment[]
> = {
  fromDb: data => {
    return data?.map(item => attachmentGetConverter.fromDb(item)) || [];
  },
};

export const attachmentUpdateConverter: TCreateConverter<
  TUpdateAttachmentUi,
  TUpdateAttachment
> = {
  toDb: data => {
    return {
      ...(data.id && { id: data.id }),
      name: data.name,
      fileUrl: data.url,
    };
  },
};

export const attachmentsUpdateConverter: TCreateConverter<
  TUpdateAttachmentUi[],
  TUpdateAttachment[]
> = {
  toDb: data => {
    const convertedArr: TUpdateAttachment[] = [];
    data.forEach(item => {
      if (item.url) {
        convertedArr.push(attachmentUpdateConverter.toDb(item));
      }
    });
    return convertedArr;
  },
};

export const inactiveStatusConverter: TConverter<EStatusUi, EStatus> = {
  fromDb: data => {
    const convertedDataMapper: {
      [key in EStatus]: EStatusUi;
    } = {
      [EStatus.ACTIVE]: EStatusUi.ACTIVE,
      [EStatus.INACTIVE]: EStatusUi.INACTIVE,
    };
    return convertedDataMapper[data];
  },
  toDb: data => {
    const convertedDataMapper: {
      [key in EStatusUi]: EStatus;
    } = {
      [EStatusUi.ACTIVE]: EStatus.ACTIVE,
      [EStatusUi.INACTIVE]: EStatus.INACTIVE,
    };
    return convertedDataMapper[data];
  },
};
