import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../Grid/Grid';
import Button from '../../../Button/Button';

const PublicHeader: FC = () => {
  const navigate = useNavigate();
  const navigateLoginPage = () => {
    navigate('login');
  };

  return (
    <Row justify="end">
      <Col>
        <Button onClick={navigateLoginPage}>Login</Button>
      </Col>
    </Row>
  );
};

export default PublicHeader;
