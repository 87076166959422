import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserQuery } from 'redux/apiSlice/user.slices';
import { Col, Row } from '../../../Grid/Grid';
import OrganizationInfo from '../components/OrganizationInfo/OrganizationInfo';
import { Text } from '../../../Typography';
import { Icon, TIconNames } from '../../../Icon';
import ProfileMenu from '../components/ProfileMenu/ProfileMenu';
import { SLine } from './PrivateHeader.style';

const PrivateHeader: FC = () => {
  const navigate = useNavigate();
  const { data } = useGetUserQuery();

  const onTicketsClick = () => {
    navigate('/app/tickets');
  };
  return (
    <Row justify="space-between" align="middle" wrap={false}>
      <Col>
        {!!data && (
          <Row gutter={[10, 0]} align="middle">
            <Col>
              {!!data && (
                <OrganizationInfo
                  avatarUrl={data.organizationLogoUrl}
                  name={data.organizationName}
                  className="mr-4"
                />
              )}
            </Col>
            <Col>
              <SLine />
            </Col>
            <Col>
              {!!data && (
                <OrganizationInfo
                  name={data?.companyName}
                  avatarUrl={data?.companyLogoUrl}
                  size="large"
                />
              )}
            </Col>
            <Col>
              <SLine />
            </Col>
            <Col className="pointer" onClick={onTicketsClick}>
              <Text fontlevel={5}>Tickets</Text>
              <Icon icon={TIconNames.ARROW_RIGHT} />
            </Col>
          </Row>
        )}
      </Col>
      <Col>
        <Row gutter={[16, 0]} align="middle">
          <ProfileMenu />
        </Row>
      </Col>
    </Row>
  );
};

export default PrivateHeader;
