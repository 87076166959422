export enum ETokensEnum {
  ID_TOKEN = 'idToken',
  REFRESH_TOKEN = 'refreshToken',
  ACCESS_TOKEN = 'accessToken',
}

export type TInitCustomSessionParams = {
  session: string;
  code: string;
  email: string;
  onSuccess?: () => void;
  onFail?: (message?: string) => void;
};
