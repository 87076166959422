import { TConverter } from '../types/converter.types';
import { TAuthUser, TAuthUserUi } from '../types/user.types';

export const userConverter: TConverter<TAuthUserUi, TAuthUser> = {
  fromDb: data => {
    return {
      email: data.email,
      role: data.role,
      companyLogoUrl: data.companyLogoUrl,
      companyName: data.companyName,
      organizationLogoUrl: data.organizationLogoUrl,
      organizationName: data.organizationName,
      organizationWebsite: data.organizationWebsite,
      timeZone: data.timeZone,
      id: data.id,
      name: data.name,
      phone: data.phone,
    };
  },
  toDb: data => {
    return {
      email: data.email,
      role: data.role,
      companyLogoUrl: data.companyLogoUrl,
      companyName: data.companyName,
      organizationLogoUrl: data.organizationLogoUrl,
      organizationName: data.organizationName,
      organizationWebsite: data.organizationWebsite,
      timeZone: data.timeZone,
      id: data.id,
    };
  },
};
