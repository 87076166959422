import {
  configureStore,
  combineReducers,
  CombinedState,
  AnyAction,
} from '@reduxjs/toolkit';
import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import testSlice from './slices/test.slice';
import { ClientPortalApi } from './apiSlice/clientPortal.slices';
import { testApi } from './apiSlice/test.slices';
import { authSlice } from './slices/auth.slice';
import { uploadFileApi } from './apiSlice/upload.slice';
import { getUserApi } from './apiSlice/user.slices';
import { ticketsApi } from './apiSlice/ticket.slice';

type TState =
  | CombinedState<{
      [x: string]: unknown;
    }>
  | undefined;

const appReducer = combineReducers({
  [ClientPortalApi.reducerPath]: ClientPortalApi.reducer,
  [testApi.reducerPath]: testApi.reducer,
  testSlice: testSlice.reducer,
  authSlice: authSlice.reducer,
  uploadSlice: uploadFileApi.reducer,
  [getUserApi.reducerPath]: getUserApi.reducer,
  [ticketsApi.reducerPath]: ticketsApi.reducer,
});

const rootReducer = (state: TState, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  // @ts-ignore
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      ClientPortalApi.middleware,
      testApi.middleware,
      uploadFileApi.middleware,
      getUserApi.middleware,
      ticketsApi.middleware,
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = cb =>
  useSelector(cb, shallowEqual);

setupListeners(store.dispatch);
